(function () {

    $(document).ready(function () {
        $('.js-details').popup({
            hoverable: true
        });
        $('.menu .item').tab();

    });

})();
